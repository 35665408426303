.Loading {
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex:1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
};